import React from 'react'
import PropTypes from "prop-types";

import PbContainer from "../../../common/containers/PbContainer";
import {GameItem} from "./GameItem";
import {TicketDetails} from "./TicketDetails";

const Ticket = ({ticket, onClick, children}) => {
    const onItemClicked = () => {
        if (onClick) {
            onClick(ticket.id);
        }
    }

    const gameItems = ticket.games.map((game, index) => {
        return (
            <GameItem game={game} hideDate={ticket.games.length === 1}></GameItem>
        )
    })


    return (
        <PbContainer label={ticket.name} actionButton={<TicketDetails ticket={ticket}></TicketDetails>}>
            <div className={`ticket ${!!onClick ? 'clickable' : ''}`} onClick={onItemClicked}>
                {gameItems}
            </div>
            {children}
        </PbContainer>
    );

};

Ticket.propTypes = {
    ticket: PropTypes.object.isRequired,
    onClick: PropTypes.func
};

export default Ticket;