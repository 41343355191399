import React, {useEffect, useState} from 'react'

import {GAME_LOBBY, START_GAME} from "../../../shared/constants/RouteContants";
import {get} from "../../../shared/services/ApiService";
import {useNavigate} from "react-router-dom";
import {PageLoader} from "../../common/widgets/PageLoader";
import PbRadio from "../../common/buttons/PbRadio";
import Ticket from "../common/game-item/Ticket";
import TicketCard from "../common/ticket-card/TicketCard";
import {translate} from "../../../shared/services/LocalizationService";

export const MyGames = () => {
    const [tickets, setTickets] = useState(null);
    const [ticketState, setTicketState] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const goToTicket = (game) => {
        navigate(GAME_LOBBY + game.id);
    }

    const getTicketsByStatus = (status) => {
        return tickets.filter(ticket => ticket.status === status);
    };

    const loadData = () => {
        get('api/tickets').then(tickets => {
            setTickets(tickets);
            setTicketState(tickets.length > 0 ? tickets[0].status : 'PRE_GAME')
            setLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return (
            <div className="props-page-body-dark">
                <PageLoader></PageLoader>
            </div>
        );
    }

    const onTicketStateUpdated = (newState) => {
        setTicketState(newState);
    }

    const stateOptions = [
        {label: translate('DRAFT'), count: getTicketsByStatus("DRAFT").length, value: "DRAFT"},
        {label: translate('PRE_GAME'), count: getTicketsByStatus("PRE_GAME").length, value: "PRE_GAME"},
        {label: translate('LIVE'), count: getTicketsByStatus("LIVE").length, value: "LIVE"},
        {label: translate('POST_GAME'), count: getTicketsByStatus("POST_GAME").length, value: "POST_GAME"},
        {label: translate('COMPLETED'), count: getTicketsByStatus("COMPLETED").length, value: "COMPLETED"}
    ]

    const filteredTickets = getTicketsByStatus(ticketState).map((ticket, index) =>
        <TicketCard key={index} onClick={() => goToTicket(ticket)} ticket={ticket}></TicketCard>
    );

    return (
        <div className="props-page-body-dark">
            <label className="games-header">My Games</label>
            <PbRadio options={stateOptions} currentValue={ticketState} onSelect={onTicketStateUpdated}
                     hideEmpty={true}></PbRadio>
            <div className="ticket-list">
                {filteredTickets}
            </div>
            {filteredTickets.length === 0 &&
                <div>You haven't entered any games. <a onClick={() => navigate(START_GAME)}>Click here to start a
                    new game</a>
                </div>
            }
        </div>
    )
}