import React, {useEffect, useState} from 'react'
import {
    ALL_GAMES,
    GAME_LOBBY,
    OPEN_GAMES,
    START_GAME
} from "../../shared/constants/RouteContants";
import {get} from "../../shared/services/ApiService";
import {PageLoader} from "../common/widgets/PageLoader";
import {useNavigate} from "react-router-dom";
import PbRadio from "../common/buttons/PbRadio";
import PbButton from "../common/buttons/PbButton";
import TicketCard from "../game/common/ticket-card/TicketCard";
import {translate} from "../../shared/services/LocalizationService";

export const DashboardActiveGames = () => {
    const [lockedTickets, setLockedTickets] = useState(null);
    const [openTickets, setOpenTickets] = useState(null);
    const [endedTickets, setEndedTickets] = useState(null);
    const [ticketState, setTicketState] = useState('PRE_GAME');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const loadData = () => {
        get('api/tickets?statuses=PRE_GAME,LIVE,POST_GAME').then(tickets => {
            setOpenTickets(tickets.filter((ticket) => ticket.status === 'PRE_GAME'));
            setLockedTickets(tickets.filter((ticket) => ticket.status === 'LIVE'));
            setEndedTickets(tickets.filter((ticket) => ticket.status === 'POST_GAME'));
            setTicketState(tickets.length > 0 ? tickets[0].status : 'PRE_GAME');
            setLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    const goToTicket = (gameId) => {
        navigate(GAME_LOBBY + gameId);
    }

    const onTicketStateUpdated = (newState) => {
        setTicketState(newState);
    }

    const getTicketSelector = () => {
        const stateOptions = [
            {label: translate("PRE_GAME"), count: openTickets.length, value: "PRE_GAME", tickets: openTickets},
            {label: translate("LIVE"), count: lockedTickets.length, value: "LIVE", tickets: lockedTickets},
            {label: translate('POST_GAME'), count: endedTickets.length, value: "POST_GAME", tickets: endedTickets}
        ]

        const filteredTickets = stateOptions.filter((option) => option.value === ticketState)[0].tickets
            .map((ticket, index) =>
                <TicketCard key={index} ticket={ticket} onClick={goToTicket}></TicketCard>
            );

        return (
            <div>
                <PbRadio options={stateOptions} currentValue={ticketState} onSelect={onTicketStateUpdated}></PbRadio>
                <div className="ticket-list">
                    {filteredTickets}
                </div>
                {filteredTickets.length === 0 &&
                    <div>You do not have any active tickets.</div>
                }
            </div>
        )
    }

    return (
        <div className="props-page-body-dark">
            <div>
                <span className="games-header">My Active Games</span>
                <a onClick={() => navigate(ALL_GAMES)}>View All Games</a>
            </div>
            <br/>
            {loading && <PageLoader></PageLoader>}
            {!loading && getTicketSelector()}
            <div className="pb-btn-row">
                <PbButton onClick={() => navigate(OPEN_GAMES)} label="Join a game"></PbButton>
                <PbButton onClick={() => navigate(START_GAME)} label="Start a game"></PbButton>
            </div>
        </div>
    )
}